@charset "UTF-8";
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@font-face {
  font-family: "Gilroy-Medium";
  src: url("./assets/fonts/regular/Gilroy-Medium.eot");
  /* IE9*/
  src: url("./assets/fonts/regular/Gilroy-Medium.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/regular/Gilroy-Medium.woff2") format("woff2"), url("./assets/fonts/regular/Gilroy-Medium.woff") format("woff"), url("./assets/fonts/regular/Gilroy-Medium.ttf") format("truetype"), url("./assets/fonts/regular/Gilroy-Medium.svg#Gilroy-Medium") format("svg");
  /* iOS 4.1- */
}
@font-face {
  font-family: "Gilroy-Bold";
  src: url("./assets/fonts/bold/Gilroy-Bold.eot");
  /* IE9*/
  src: url("./assets/fonts/bold/Gilroy-Bold.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/bold/Gilroy-Bold.woff2") format("woff2"), url("./assets/fonts/bold/Gilroy-Bold.woff") format("woff"), url("./assets/fonts/bold/Gilroy-Bold.ttf") format("truetype"), url("./assets/fonts/bold/Gilroy-Bold.svg#Gilroy-Bold") format("svg");
  /* iOS 4.1- */
}
@font-face {
  font-family: "Whitney-Book";
  src: url("./assets/fonts/book/Whitney-Book.eot");
  /* IE9*/
  src: url("./assets/fonts/book/Whitney-Book.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/book/Whitney-Book.woff2") format("woff2") url("./assets/fonts/book/Whitney-Book.ttf") format("truetype");
}
@font-face {
  font-family: "Whitney-Light";
  src: url("./assets/fonts/light/Whitney-Light.eot");
  /* IE9*/
  src: url("./assets/fonts/light/Whitney-Light.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/light/Whitney-Light.woff2") format("woff2"), url("./assets/fonts/light/Whitney-Light.ttf") format("truetype");
  /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {
  font-family: "Whitney-Bold";
  src: url("./assets/fonts/bold/Whitney-Bold.eot");
  /* IE9*/
  src: url("./assets/fonts/bold/Whitney-Bold.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/bold/Whitney-Bold.woff2") format("woff2"), url("./assets/fonts/bold/Whitney-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Whitney-Medium";
  src: url("./assets/fonts/regular/Whitney-Medium.eot");
  /* IE9*/
  src: url("./assets/fonts/regular/Whitney-Medium.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/regular/Whitney-Medium.woff2") format("woff2"), url("./assets/fonts/regular/Whitney-Medium.ttf") format("truetype");
  /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
* {
  font-family: Whitney-Medium !important;
  font-size: 13px;
  letter-spacing: 0em !important;
}

.font-bold {
  font-family: Whitney-Bold !important;
}

.font-reg {
  font-family: Whitney-Medium !important;
}

.font-light {
  font-family: Whitney-Light !important;
}

.f-s-25 {
  font-size: 25px !important;
}

.f-s-24 {
  font-size: 24px !important;
}

.f-s-28 {
  font-size: 28px !important;
}

.f-s-22 {
  font-size: 22px !important;
}

.ion-padding-t-3x {
  padding-top: 1.5em;
}

.ion-padding-b-2x {
  padding-bottom: 1.5em;
}

.section-padding {
  padding: 2em 4em !important;
}

.section-padding-v {
  padding: 0em 4em !important;
}

.section-padding-2v {
  padding: 0em 8em !important;
}

.section-padding-3v {
  padding: 0em 12em !important;
}

.flex-center {
  display: flex;
  justify-items: center;
  align-items: center;
}

.text-white {
  color: #ffffff !important;
}

.padding-t-b--5 {
  padding: 0.5em 0em;
}

.padding-t--5 {
  padding-top: 0.5em;
}

.footer-title {
  color: #49b821;
  font-weight: bold;
}

.normal-font {
  font-weight: normal;
}

.partner-us {
  width: 70%;
  border-radius: 5px;
  border: 0.5px solid;
  margin-bottom: 1em;
}

.height-1 {
  height: 1px;
  min-height: 1px;
}

.fullscreen .popover-content.sc-ion-popover-md {
  min-width: 50% !important;
  height: 60%;
}

.mobile-card {
  box-shadow: none !important;
  position: relative !important;
  margin: 0 auto;
}

.mobile-card-img {
  margin-bottom: 10px;
  width: 100%;
  height: 140px;
  overflow: hidden;
}

.mobile-slider-text {
  position: absolute;
  bottom: 1em;
  background-color: rgba(255, 255, 255, 0.1);
  left: 0px;
  right: 0px;
  width: 40%;
  margin: 0 auto;
}

.margin-auto {
  margin: 0 auto;
}

.add-to-cart,
.buy-now {
  --color: #ffffff !important;
  min-width: 210px;
  font-size: 16px;
  --border-radius: 0px;
  margin: 0px;
  --padding-top: 20px;
  --padding-bottom: 20px;
  height: auto;
}

.out-of-stock-detail {
  --color: #191919 !important;
  min-width: 210px;
  font-size: 16px;
  --border-radius: 0px;
  margin: 0px;
  --padding-top: 25px;
  --padding-bottom: 25px;
  height: auto;
  --box-shadow: none !important;
  --background: #ffffff;
  --border-color: #191919;
  border: 1px solid;
}

.out-of-stock {
  --color: #ffffff !important;
  min-width: 210px;
  font-size: 16px;
  --border-radius: 0px;
  margin: 0px;
  --padding-top: 25px;
  --padding-bottom: 25px;
  height: auto;
  --background: #191919;
  --border-color: #191919;
}

.bold {
  font-weight: bold !important;
}

.right-border {
  border-right: 1px solid #dddddd;
}

.border {
  border: 1px solid #dddddd;
}

.border-bottom {
  border-bottom: 1px solid #dddddd;
}

.footer-links ion-item {
  --min-height: 30px !important;
  --height: 30px !important;
}

.store-img {
  width: 50% !important;
  --min-height: 30px !important;
  --height: 30px !important;
}

.discount {
  margin-left: 32px;
}

.original-price {
  font-size: 14px;
  text-decoration: line-through;
  color: #cccccc;
}

.offer {
  margin-left: 10px;
  font-size: 14px;
  color: red;
}

.share {
  color: #292828;
  font-size: 14px;
  margin-left: 10px;
  font-weight: normal;
}

.label {
  color: #cccccc;
}

.variation {
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
  overflow: hidden;
  display: inline-block;
}

.variation img {
  width: 60px;
}

.highlight {
  margin: 0px;
  padding: 0px;
  color: #333333;
  list-style-position: inside;
  line-height: 25px;
}

.bag-section {
  border: 1px solid #dddddd;
  border-radius: 3px;
  margin-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
}

.p-l-5 {
  padding-left: 5px;
}

.bag-summary-section {
  border: 1px solid #dddddd;
  border-radius: 3px;
}

.space-between {
  align-items: flex-end;
}

.toolbar-image {
  height: 50px;
  vertical-align: middle;
}

.businesstype-card,
.category-card {
  background-color: #f5f5f5;
  border-radius: 15px;
  color: #202124;
  font-weight: 600;
  font-size: 13px;
  box-shadow: none;
  margin: 0px auto !important;
  margin-bottom: 16px !important;
}

.category-card-2 ion-img {
  padding: 5px;
  padding-bottom: 0px;
}

.category-card-2 {
  background-color: transparent;
  box-shadow: 0 2px 7px 0 rgba(222, 222, 222, 0.5);
  max-width: 274px;
  width: 90%;
  margin: 10px auto;
}

.businesstype-card {
  width: 80%;
}

.category-card {
  width: 70%;
}

.hover {
  cursor: pointer;
}

.searchbar-input {
  border-radius: 5px !important;
  font-size: 13px !important;
  box-shadow: none !important;
  border: 1px solid #f5f5f6 !important;
  background: #f5f5f6 !important;
}

.search-input {
  box-shadow: none;
}

.searchbar-input:focus {
  background: #ffffff !important;
  border: 1px solid #eaeaec;
}

.f-s-15 {
  font-size: 15px;
}

.f-s-16 {
  font-size: 16px !important;
}

.f-s-18 {
  font-size: 18px !important;
}

.f-s-20 {
  font-size: 20px !important;
}

.best-ttl {
  font-size: 16px !important;
  margin-top: 3px;
  margin-bottom: 7px !important;
  text-align: left;
  color: #000000;
}

.best-everyday {
  background-color: transparent;
  box-shadow: none;
  width: 100%;
  max-width: 230px;
  margin: 0 auto;
  padding: 10px;
  padding-bottom: 0px !important;
}

.add-btn {
  --color: #191919;
  --border-color: #191919;
  --padding-start: 20px;
  --padding-end: 20px;
  --border-width: 1px;
  text-transform: initial;
  margin: 0px !important;
}

.out-stock {
  --color: #ffffff;
  --background: #191919;
  --border-color: #191919;
  --padding-start: 20px;
  --padding-end: 20px;
  --border-width: 1px;
  text-transform: initial;
  margin: 0px !important;
  cursor: no-drop !important;
}

.symbol-btn {
  --padding-start: 4px;
  --padding-end: 4px;
  margin: 0px;
}

.bag-symbol-btn {
  --border-width: 1px;
  --padding-start: 4px;
  --padding-end: 4px;
}

.quick-add-input {
  font-size: 16px;
  --color: #000000;
  text-align: center;
  padding: 0px !important;
}

.cat-checkbox {
  margin: 0px;
  margin-right: 10px;
  --background-checked: #e5161e;
  --border-color-checked: #e5161e;
  --size: 12px;
  --border-width: 1px;
  --checkmark-width: 5px;
}

.cat-checkbox-container {
  margin-bottom: 10px;
}

.cat-label {
  font-size: 13px !important;
  margin-left: 5px;
}

.relative {
  position: relative;
}

.prev-btn,
.next-btn {
  position: absolute;
  top: 45%;
  z-index: 999;
  --background: #ffffff;
  --color: #000000;
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 40px;
  --padding-bottom: 40px;
}

.prev-btn {
  left: 0px;
}

.next-btn {
  right: 0px;
}

.f-s-14 {
  font-size: 14px !important;
}

.f-s-13 {
  font-size: 13px !important;
}

.f-s-12 {
  font-size: 12px !important;
}

.bag-qty {
  text-align: center;
  border: 1px solid #dddddd;
  --padding-bottom: 5px;
  --padding-top: 5px;
  --padding-start: 5px;
  --padding-end: 5px;
}

.no-padding-h {
  padding-top: 0px;
  padding-bottom: 0px;
}

.detail-breadcrum {
  color: #878787 !important;
  font-size: 12px !important;
}

.red-outline-btn {
  --color: #e5161e !important;
  --border-color: #e5161e !important;
}

.black-outline-btn {
  --color: #000000 !important;
  --border-color: #000000 !important;
  --border-width: 1px;
}

.red-filled-btn {
  --color: #ffffff !important;
  --background: #e5161e !important;
  --border-radius: 3px;
}

.coupon-input {
  --background: #f5f5f5;
}

.heading-border {
  border-bottom: 1px solid #dddddd;
}

.heading-border-top {
  border-top: 1px solid #dddddd;
}

.no-padding {
  padding-left: 0px;
  padding-right: 0px;
}

.no-padding-v {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.full-height-wo-toolbar {
  height: calc(100vh - 90px);
}

.avail-on {
  background-color: #edf1f4;
}

.app-link {
  width: 80%;
  margin: 0 auto;
  border-radius: 40px;
}

.height-100 {
  height: 100%;
}

.zearo-input-container {
  border: 1px solid #dddddd;
  border-radius: 8px;
  --border-style: none;
}

.item-interactive.ion-valid {
  --highlight-background: rgba(229, 22, 30, 0.5) !important;
}

.item-interactive.item-has-focus {
  --full-highlight-height: 1px !important;
}

.zearo-input {
  border: none !important;
  --highlight-color-valid: transparent !important;
  --highlight-background: transparent !important;
}

.terms-signup {
  margin-right: 10px;
  margin-left: 0px;
}

.profile-menu {
  border-radius: 8px;
}

.profile-menu,
.profile-menu-mobile {
  background-color: #f5f5f5;
}

.profile-menu-mobile div {
  width: 25%;
  float: left;
  text-align: center;
}

.profile-menu-mobile {
  margin-top: 21px;
  overflow: hidden;
  padding: 0px 15px;
}

.menu-button {
  display: block;
  padding: 15px;
}

.thankyou-query {
  display: flex;
  justify-content: start;
  align-items: center;
}

.thankyou-section {
  background-color: #f5f5f6 !important;
  padding: 0 3em;
}

.block {
  display: block;
}

.menu-button,
.menu-button-mobile {
  background: transparent;
  box-shadow: none;
  color: #000000;
  text-transform: initial;
  width: 100%;
  text-decoration: none;
  cursor: pointer;
}

.menu-button-mobile {
  padding: 15px 0px;
  display: inline-block;
  width: 50%;
}

.menu-button-mobile.selected-menu {
  border-bottom: 3px solid #e5161e;
  color: #e5161e;
}

.red-link {
  color: #e5161e !important;
  text-decoration: none;
}

.menu-button.selected-menu {
  border-left: 3px solid #e5161e;
  background-color: #ffffff;
  color: #e5161e;
}

.myorder-list {
  border: 1px solid #dddddd;
  border-radius: 5px;
  margin-bottom: 16px;
}

.order-header {
  background-color: #f5f5f5;
  padding: 15px 10px;
}

.order-header-action {
  padding: 15px 10px;
}

.order-no {
  color: #fa8138;
  font-size: 18px;
}

.product-list {
  border-bottom: 1px solid #dddddd;
  padding-left: 26px;
  padding-right: 26px;
}

.product-list:last-child {
  border-bottom: none;
}

.captal-text {
  text-transform: capitalize !important;
}

.order-confirm {
  width: 40px;
  min-width: 40px;
}

.uppercase {
  text-transform: uppercase;
}

.order-info,
.status-detail {
  font-weight: bold;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 3px;
  font-size: 13px;
  display: inline-block;
}

.order-info {
  background-color: #fa8138;
}

.status-detail {
  background-color: #16aa37;
}

.status-label {
  color: #333333;
  font-size: 14px;
}

.no-padding-divider {
  min-height: 0px;
  height: 0px;
}

.no-t-margin {
  margin-top: 0px;
}

.sub-title {
  color: #ababab;
  margin-bottom: 10px;
}

.cat-filter {
  padding: 0px 15px;
  margin-left: 0px !important;
  margin-right: 10px !important;
  height: 25px;
}

.selected-filter {
  color: #e5161e !important;
  border-color: #e5161e !important;
}

.no-margin-left {
  margin-left: 0px;
}

.margin-r-10 {
  margin-right: 10px;
}

.margin-r-12 {
  margin-right: 12px;
}

.margin-r-5 {
  margin-right: 5px;
}

.margin-l-5 {
  margin-left: 5px;
}

.header {
  --padding-top: 0px;
  --padding-bottom: 0px;
}

ion-header {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
  z-index: 999999;
}

.hnf-svg-icon {
  display: inline-block;
  height: 24px;
  width: 30px;
  fill: currentColor;
}

.menu-btn {
  --padding-start: 0.7em;
  --padding-end: 0.7em;
  height: 76px !important;
}

.qsale {
  color: black;
  text-decoration: none;
  font-family: Whitney-Bold !important;
}

ion-segment-button ion-label {
  font-size: 14px !important;
  margin-bottom: 0px;
  color: #000000;
}

.segment-button-checked ion-label {
  color: #e5161e;
}

ion-segment-button {
  --indicator-color: #e5161e;
  --color-checked: #e5161e;
  --background-checked: none;
}

.color-red {
  color: #e5161e;
}

.segment-button-checked {
  font-weight: bold !important;
}

.no-b-margin {
  margin-bottom: 0px !important;
}

.filter-section {
  padding: 0px 36px;
}

.cat-count {
  font-size: 11px;
  color: #94969f;
}

.location-autocomplete {
  width: 100%;
  padding: 10px 0px;
  outline: 0px;
  border: 0px;
}

.weight-500 {
  font-weight: 500;
}

.detail-desc {
  border-top: 1px solid #f0f0f0;
}

.border-bottom-1 {
  border-bottom: 1px solid #f0f0f0;
}

.padding-b {
  padding-bottom: 15px;
}

.padding-t-14 {
  --padding-top: 14px !important;
  padding-top: 14px !important;
}

.margin-t-14 {
  margin-top: 14px !important;
}

.color-gray {
  color: rgba(0, 0, 0, 0.7) !important;
}

.b-m-8 {
  margin-bottom: 8px !important;
}

.b-m-5 {
  margin-bottom: 5px !important;
}

.color-black {
  color: #000000 !important;
}

.color-hash {
  color: #7a7a7a !important;
}

.no-shadow {
  --box-shadow: none;
  --transition: none;
}

.loadar svg {
  width: 50px;
  height: 50px;
  margin: 20px;
  display: inline-block;
}

.padding-25-h {
  --padding-top: 25px;
  --padding-bottom: 25px;
}

ion-radio {
  --color-checked: #e5161e;
  margin-right: 15px;
}

.margin-18-h,
.d-address {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}

.b-m-12 {
  margin-bottom: 12px !important;
}

.bag-count {
  background-color: #f16565;
  white-space: nowrap;
  text-align: center;
  line-height: 18px;
  padding: 0 6px;
  height: 18px;
  background: #ff3f6c;
  position: absolute;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  left: 20px;
  top: 20px;
  font-weight: 700;
}

ion-toast {
  --start: 1;
  top: 76px;
  left: 0px;
}

.page-loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 5px;
  border-radius: 20px;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  z-index: 6;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.09);
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.09);
  z-index: 999;
}

.black-color {
  color: #000000;
}

.submit-btn {
  color: #ffffff !important;
  background: #e5161e !important;
  border-radius: 3px;
  text-transform: uppercase;
  padding-top: 12px;
  padding-bottom: 12px;
  max-height: 36px;
  display: flex;
  justify-items: center;
  align-items: center;
}

.best-everyday:hover {
  box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.07) !important;
  --webkit--box-shadow: 0 2px 16px 4px rgb(40 44 63 / 7%) !important;
}

.no-margin {
  margin: 0px !important;
}

.cat-padding {
  padding-top: 4px !important;
  padding-bottom: 6px !important;
}

.margin-20-h {
  margin-top: 20px;
  margin-bottom: 20px;
}

.m-b-8 {
  margin-bottom: 8px;
}

.m-b-16 {
  margin-bottom: 16px;
}

.m-r-10 {
  margin-right: 10px;
}

.p-l-32 {
  padding-left: 32px !important;
}

.breadcrum {
  cursor: pointer;
}

ion-segment-button {
  flex: 0 !important;
  min-height: 24px;
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-bottom: 5px;
  min-width: auto;
  margin-right: 10px;
  margin-left: 10px;
}

[aria-checked=true].sc-ion-alert-md .alert-checkbox-icon.sc-ion-alert-md {
  background-color: #e5161e !important;
  border-color: #e5161e !important;
}

.alert-radio-inner.sc-ion-alert-md {
  background-color: #e5161e !important;
}

.alert-button.sc-ion-alert-md:last-child {
  color: #e5161e !important;
}

.alert-radio-inner.sc-ion-alert-md,
[aria-checked=true].sc-ion-alert-md .alert-radio-icon.sc-ion-alert-md {
  border-color: #e5161e !important;
}

.total-amt {
  text-align: right;
}

.page-loader {
  height: 20px;
  width: 20px;
  -webkit-animation: spinner-rotate 0.8s linear infinite;
  animation: spinner-rotate 0.8s linear infinite;
  border: 2px solid #e5161e;
  border-right-color: transparent;
  border-radius: 20px;
}

.button-loader {
  height: 16px;
  width: 16px;
  -webkit-animation: spinner-rotate 0.8s linear infinite;
  animation: spinner-rotate 0.8s linear infinite;
  border: 2px solid #ffffff;
  border-right-color: transparent;
  border-radius: 20px;
}

.outline-button-loader {
  height: 16px;
  width: 16px;
  -webkit-animation: spinner-rotate 0.8s linear infinite;
  animation: spinner-rotate 0.8s linear infinite;
  border: 2px solid #000000;
  border-right-color: transparent;
  border-radius: 20px;
}

.outline-thin-button-loader {
  height: 16px;
  width: 16px;
  -webkit-animation: spinner-rotate 0.8s linear infinite;
  animation: spinner-rotate 0.8s linear infinite;
  border: 1px solid #000000;
  border-right-color: transparent;
  border-radius: 20px;
}

@keyframes spinner-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
/* Laptop devices (laptop, 992px and down) */
@media only screen and (max-width: 992px) {
  .filter-section {
    padding: 0px 20px;
  }

  .p-l-32 {
    padding-left: 0px !important;
  }

  .section-padding-3v {
    padding: 0em 6em !important;
  }

  .section-padding-v {
    padding: 0em 4em !important;
  }

  .add-to-cart,
.buy-now {
    min-width: 210px;
  }

  .auth-section {
    --ion-grid-columns: 8;
  }

  .avail-on {
    --ion-grid-columns: 12;
  }

  .action-section,
.action-section-w-p {
    --ion-grid-columns: 12;
  }

  .action-section {
    padding: 0px;
  }

  .md-col-padding {
    padding: 5px;
  }
}
/* Small devices (tablet, 768px and down) */
@media only screen and (max-width: 768px) {
  .product-list {
    padding-left: 0px;
    padding-right: 0px;
  }

  .section-padding-2v {
    padding: 0em 1em !important;
  }

  .filter-section {
    padding-right: 0px;
    padding-left: 0px;
  }

  .section-padding-3v {
    padding: 0em 2em !important;
  }

  .status-text {
    font-size: 13px;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .section-padding {
    padding: 0em 0em !important;
  }

  .section-padding-v,
.section-padding-2v {
    padding: 0em 1em !important;
  }

  .swiper-slide img {
    border-radius: 0px !important;
  }

  .toolbar-container {
    --padding-top: 0px !important;
    --padding-bottom: 0px !important;
  }

  .section-padding-3v {
    padding: 0em 0em !important;
  }

  .add-to-cart,
.buy-now {
    min-width: 130px !important;
    width: 95% !important;
  }

  .menu-button-mobile {
    width: 70%;
  }
}
@media only screen and (max-width: 380px) {
  .total-amt {
    text-align: left;
  }

  .menu-button-mobile {
    width: 100%;
    font-size: 12px;
  }
}
@media only screen and (max-width: 430px) {
  .menu-button-mobile {
    width: 80%;
  }

  .status-text {
    font-size: 11px;
  }
}
@media only screen and (max-width: 575px) {
  .powered-by {
    text-align: center !important;
  }
}
@media only screen and (max-width: 1550px) and (min-width: 991px) {
  .section-padding-2v {
    padding: 0em 2em !important;
  }

  .section-padding-3v,
.section-padding-v {
    padding: 0em 1em !important;
  }

  .auth-section {
    --ion-grid-columns: 8;
  }

  .avail-on {
    --ion-grid-columns: 12;
  }

  .action-section,
.action-section-w-p {
    --ion-grid-columns: 12;
  }

  .checkout-section {
    --ion-grid-columns: 12;
  }

  .total-amt {
    text-align: center;
  }
}
@media only screen and (max-width: 1200px) {
  .add-to-cart,
.buy-now {
    min-width: 160px;
  }

  .margin-r-10 {
    margin-right: 0px;
  }
}
@media screen and (max-width: 1376px) and (min-width: 1200px) {
  .everyday-section ion-col:last-child,
.cat-section ion-col:last-child {
    display: none !important;
  }

  .everyday-section ion-col,
.cat-section ion-col,
.product-list ion-col {
    --ion-grid-columns: 10;
  }

  .section-padding-3v {
    padding: 0em !important;
  }

  .add-to-cart,
.buy-now {
    min-width: 190px;
  }

  .auth-section {
    --ion-grid-columns: 8;
  }

  .avail-on {
    --ion-grid-columns: 12;
  }

  .action-section,
.action-section-w-p {
    --ion-grid-columns: 12;
  }

  .checkout-section {
    --ion-grid-columns: 10;
  }

  .profile-section {
    --ion-grid-columns: 11;
  }
}
.product-description p,
.product-description ul li {
  color: #282c3f !important;
  font-size: 14px;
}

.product-description ul li {
  list-style-position: inside;
}

.product-description ul {
  padding: 0px;
}

.product-description-empty {
  width: 100%;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 13px;
  background-color: #f5f5f6 !important;
  color: #9f9fa0;
  margin-top: 14px;
  border-radius: 5px;
}

.terms-checkbox {
  --padding-start: 0px !important;
}

.padding-v2x {
  padding-top: 2em;
  padding-bottom: 2em;
}

.popover-content {
  top: 76px !important;
  -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1) !important;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 0px !important;
  z-index: 1 !important;
}

.product-container::-webkit-scrollbar,
.product-detail-container::-webkit-scrollbar,
.profile-container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.product-container,
.product-detail-container,
.profile-container {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.product-container {
  height: calc(100vh - 150px);
  overflow-y: auto;
}

.product-detail-container {
  height: calc(100vh - 76px);
  overflow-y: auto;
}

.static-page {
  min-height: calc(100vh - 76px - 66px - 181px - 64px);
}

.no-ripple {
  --ripple-color: transparent !important;
  --background-hover: transparent !important;
}

.profile-container {
  height: calc(100vh - 147px - 328px);
  overflow-y: auto;
}

ion-spinner {
  --color: #e5161e;
}

.header-height {
  height: 76px;
}

.product-d-img {
  width: 100% !important;
  max-height: 70vh !important;
}

.detail-image {
  height: calc(100vh - 79px - 30px - 181px);
  position: fixed;
}